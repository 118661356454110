// REACT
import React from "react"
// MUI
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// STYLES
const useStyles = makeStyles({
  heading: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginTop: "4rem",
    marginBottom: "2rem",
  },
})

// COMPONENT FUNCTION
const PumpoutBoatsPage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsLogo: file(relativePath: { eq: "alcar-boats-logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pumpGear: file(relativePath: { eq: "pump-gear.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pumpoutBoatCrossSection: file(
        relativePath: { eq: "pumpout-boat-cross-section.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Pumpout Boats">
      <PageTitle title="PUMPOUT BOATS" />
      <Typography variant="h2" className={classes.heading}>
        Practical Solutions Today ...
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Waterfront Managers and Harbormasters are responsible for cleaning
            and maintaining our marinas for the enjoyment of boating enthusiasts
            today and in the future. It’s a big job that requires sturdy,
            dependable equipment that performs predictably and efficiently no
            matter what the conditions. That’s why so many marina owners rely on
            the Alcar Environmental pumpout boat to meet their changing needs.
            Just look around you, and you’ll see it’s true.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.alcarBoatsLogo.childImageSharp.fluid}
            caption="Alcar Boats Logo"
            maxWidth={400}
          />
        </Grid>
      </Grid>
      <Typography variant="h2" className={classes.heading}>
        ... For Tomorrow's Clean Environment
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography paragraph>
            The <Link to="/alcar-environmental">Alcar Environmental</Link>{" "}
            pumpout boat is the perfect answer for a high quality, low
            maintenance, customizable, multi-purpose utility craft at a
            cost-effective price. It is designed to be user-friendly with a
            smooth riding V-hull and a fully self-bailing cockpit large enough
            for the most demanding jobs yet easily managed by a single operator.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Fitted with a below deck gallon molded fiberglass tank and an Edson
            electric diaphragm pump, it makes short work of sewage pumpout and
            transfer jobs. The tank is quickly and easily emptied into a
            shore-side station, taking less than 15 minutes to complete hookup
            and transfer. The connections for both pumpout and discharge are
            simple and mistake-proof, allowing minimal training time for
            operators. The Alcar Enviromental pumpout boat’s smooth molded,
            easy-clean tank makes shut down simple. Coil up the hoses, close the
            hatch and even the most discerning yachtsman won’t know it’s a honey
            wagon.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.pumpoutBoatCrossSection.childImageSharp.fluid}
            caption="Pumpout Boat Cross Section"
            maxWidth={400}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.pumpGear.childImageSharp.fluid}
            caption="Pump Gear"
            maxWidth={400}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            With the pump gear stowed, your Alcar Environmental pumpout boat
            becomes the perfect workboat. Use it for search and rescue, towing,
            harbor patrol, dock maintenance, shellfish surveillance, oil spill
            control and clean up. With a long list of optional accessories, from
            dive platforms to stainless steel T-tops, each Alcar is built to
            your specifications at production-line affordability. The Alcar
            Environmental pumpout boat works as hard as you do to save time and
            money.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default PumpoutBoatsPage
